import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NewDocumentService} from '../../@shared/quick-sale/new-document/new-document.service';
import {Unsubscribe} from '../../@shared/unsubscribe';
import {map, takeUntil} from 'rxjs/operators';
import {TerminalsService} from '../../_terminals/services/terminals.service';
import {Observable} from 'rxjs';
import {Terminal, TerminalState} from '../../_terminals/interfaces/terminals.interface';
import {BlackListInterface} from '../../reports/black-list/black-list.interface';
import {OrderStatus} from '../all-quick-sale/interface';
import {WinnerListService} from '../../@shared/reports/winner-list.service';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends Unsubscribe implements OnInit {

    @ViewChild('DontCallNotice', {static: true}) DontCallNotice: TemplateRef<any> | undefined;
    @ViewChild('NotEnoughBonuses') NotEnoughBonuses;

    constructor(public DocService: NewDocumentService,
                private translate: TranslateService,
                private route: ActivatedRoute,
                private router: Router,
                public calendar: NgbCalendar,
                public formatter: NgbDateParserFormatter,
                private modalService: NgbModal,
                private terminalsService: TerminalsService,
                private winnerListService: WinnerListService,
                ) {
        super();
    }

    maxBonuses = 0;
    giftCount = 0;
    blackList: BlackListInterface;
    place: string = localStorage.getItem('place');
    mode: string;
    currency = JSON.parse(localStorage.getItem('cityInfo')).country.currency;
    private id: number;
    time = {hour: 13, minute: 30};
    model: NgbDateStruct;
    form: any;
    terminals$: Observable<Terminal[]>;
    page = 4;
    inputSearch: string;
    loader = true;
    orderId: number;
    lastBirthday: any;
    formCheckOnEdit: {
        formBefore: any;
        formAfter: any;
    } = {
        formBefore: {},
        formAfter: {}
    };
    linkedProducts: any;
    constructorProducts = false;
    disabledProducts: number[] = [];
    uniqIndexForProduct: number;
    selectedIndex = -1;
    selectedProducts: any[] = [];
    indexOfOrder: number;
    public isEmpty(myVar): boolean {
        return (myVar && (Object.keys(myVar).length === 0));
    }


    ngOnInit(): void {
        this.terminals$ = this.terminalsService.get()
            .pipe(
                map(element => element.filter(item => item.state === TerminalState.Active))
            );
        this.createForm();
        this.DocService.getAllCat()
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
            }, () => {
            }, () => {
                this.DocService.getAllProduct()
                    .pipe(takeUntil(this.$destroy))
                    .subscribe(() => {
                    }, () => {
                    }, () => {
                        this.DocService.getAllZones()
                            .pipe(takeUntil(this.$destroy))
                            .subscribe(() => {
                            }, () => {
                            }, () => {
                                this.form.get('zone').setValue(this.DocService.zones[0].id);
                                this.DocService.getAllDistricts()
                                    .pipe(takeUntil(this.$destroy))
                                    .subscribe(() => {
                                    }, () => {
                                    }, () => {
                                        this.DocService.getAllPlaces()
                                            .pipe(takeUntil(this.$destroy))
                                            .subscribe(() => {
                                                this.form.get('place').setValue(localStorage.getItem('place'));
                                            }, () => {
                                            }, () => {
                                                this.DocService.getAllTerminals()
                                                    .pipe(takeUntil(this.$destroy))
                                                    .subscribe(() => {
                                                    }, () => {
                                                    }, () => {
                                                        this.DocService.getDeliveryTime();
                                                        this.mode = String(this.route.snapshot.params.mode);
                                                        this.id = this.route.snapshot.params.id;
                                                        this.editMode();
                                                    });
                                            });
                                    });
                            });
                    });
            });
        this.changeBonusPercent();
    }

    openNewWindow = () => {
        window.open('/reports/winner-list', '_blank');
    }

    createForm = () => {
        this.form = new FormGroup({
            phone: new FormControl(null, [Validators.required, Validators.minLength(10)]),
            district: new FormControl(null, [Validators.required]),
            zone: new FormControl(null, [Validators.required]),
            street: new FormControl(null, [Validators.required]),
            last_address: new FormControl(null ),
            house: new FormControl(null, [Validators.required]),
            attic: new FormControl(null),
            floor: new FormControl(null),
            comment: new FormControl(null),
            apt: new FormControl(null),
            ord: new FormControl(null, [Validators.required]),
            edu: new FormControl(null, [Validators.required]),
            place: new FormControl(localStorage.getItem('place'), [Validators.required]),
            sale: new FormControl(0, [Validators.required]),
            pickup_check: new FormControl(false),
            terminal: new FormControl({value: null, disabled: true}, [Validators.required]),
            terminal_check: new FormControl(false),
            birthday_check: new FormControl(false),
            personnel: new FormControl(false),
            datetime: new FormControl({value: null, disabled: true}, [Validators.required]),
            pre_order_check: new FormControl(false),
            bonuses: new FormControl(0)
        });
    }
    closeOrder = () => {
        if (this.route.snapshot.params.from === 'accounting') {
            this.router.navigate([`/cash/accounting/client`]).then(r => {
            });
        } else {
            this.router.navigate([`/quick-sale`]).then(r => {
            });
        }
    }
    actionOrder = (mode, id?, content?) => {

        if (this.form.get('personnel').value === true) {
            this.form.get('phone').setValue('personnel3303');
        }

        switch (mode) {
            case 'delete':
                this.DocService.deleteOrder(id);
                break;
            case 'put':
                this.loader = true;
                this.DocService.updateOrder(this.form.value).subscribe(() => {
                }, () => {
                    this.loader = false;
                }, () => {
                    this.DocService.saveEditHistory(this.saveEditableColumn()).subscribe({
                        next: value => console.log(value)
                    });
                    if (this.route.snapshot.params.from === 'accounting') {
                        this.router.navigate([`/cash/accounting/client`]).then(r => {
                            this.loader = false;
                        });
                    } else {
                        this.router.navigate([`/quick-sale`]).then(r => {
                            this.loader = false;
                        });
                    }

                });
                break;
            case 'insert':
                this.loader = true;
                this.DocService.insertToBase(this.form.value, this.mode, this.id).subscribe((json: any) => {
                    this.printDoc(content, json.order_id);

                }, error => {
                    this.maxBonuses = 0;
                    if (error.error.message.includes('no enough bonuses')) {
                        this.maxBonuses = Number(error.error.message.split(':')[0]);
                    }
                    this.modalService.open(this.NotEnoughBonuses).closed.subscribe({
                        next: () => {
                            this.form.get('bonuses').setValue(this.maxBonuses ?? 0);
                        }
                    });
                    this.loader = false;
                }, () => {
                });
                break;
        }
    }
    printDoc = (content, id) => {
        this.DocService.getInfoAboutCities().subscribe({
            next: (json: any) => {
                if (json.check_type == 'alternative') {
                    this.orderId = id;
                    this.modalService.open(content, {scrollable: true});
                }
                this.router.navigate([`/quick-sale`]).then(r => {
                    this.loader = false;
                });
            }
        });
    }

    onlyFutureDate(event): void {
        // event.preventDefault();
    }

    addToOrder = id => {
        this.DocService.addToOrder(id, this.form.value);
        this.inputSearch = '';
    }


    addToPartsOrder = ( prod, id, index, object) => {
        this.DocService.getConstructorProducts(prod, id, this.form.value, this.uniqIndexForProduct, object.id);
        this.disableRequired(this.selectedProducts);
        this.inputSearch = '';
    }

    changeQuantity = indexDoc => {
        this.DocService.changeQuantity(indexDoc, this.form.value);
    }

    changeQuantityCardsOfParts = (indexDoc, order) => {
        this.selectedProducts = order;
        this.DocService.changeQuantityCardsOfParts(indexDoc, this.form.value, order);
    }

    changeSale = i => {
        this.DocService.changeSale(i);
    }

    deleteProductFromCard = i => {
        this.constructorProducts = false;
        this.selectedIndex = i;
        this.disableRequired(this.selectedProducts);
        this.DocService.deleteProductFromCard(i);
    }

    deleteProductParts = (i, object) => {
        this.selectedProducts = object;
        this.disabledProducts.forEach((prodId, j) => {
            if (prodId === object.childs[i].linkedProductCategory){
                this.disabledProducts.splice(j, 1);
            }
        });
        this.DocService.deleteProductFromCardOrderParts(i, object);
    }

    changeCatId = id => {
        this.constructorProducts = false;
        this.selectedIndex = -1;
        this.DocService.catId = id;
        this.DocService.getAllProduct().subscribe(() => {
        }, () => {
        }, () => {
            this.DocService.getAllProduct().subscribe().unsubscribe();
        });
    }

    getLinkedProducts(obj, i): void {
        this.uniqIndexForProduct = i;
        this.selectedIndex = i;
        this.constructorProducts = true;
        this.DocService
            .getLinkedProducts(obj.product_id)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: (value) => {
                    this.linkedProducts = value.data;
                    this.selectedProducts = obj;
                    this.disableRequired(obj);

                },
            });
    }
    disableRequired(obj): void{
            this.linkedProducts?.forEach((linkedProduct, i) => {
                if (linkedProduct.required === 1) {
                    let found = false;
                    let categoryId = 0;
                    linkedProduct.products.forEach((product) => {
                        obj.childs?.forEach((child) => {
                            if (product.id === child.product_id) {
                                found = true;
                            }
                        });
                    });
                    if (found) {
                        categoryId = linkedProduct.id;
                        if (!this.disabledProducts.includes(categoryId)) {
                            const index = this.disabledProducts.indexOf(categoryId);
                            if (index === -1) {
                                // Если categoryId отсутствует, добавляем его
                                this.disabledProducts.push(categoryId);
                            } else {
                                // Если categoryId уже присутствует, удаляем его  только если он не является текущим
                                if (categoryId !== this.uniqIndexForProduct) {
                                    this.disabledProducts.splice(index, 1);
                                }
                            }
                        }
                    }
                } else {
                    // Очищаем disabledProducts, если категория не требует обязательного выбора
                    this.disabledProducts = [];
                }
            });
    }


    removeFromQuantity = i => {
        this.DocService.removeFromQuantity(i, this.form.value);
    }

    removeFromQuantityCardOrderParts = (i, object) => {
        this.DocService.removeFromQuantityCardOrderParts(i, this.form.value, object);
    }
    addToQuantity = i => {
        this.DocService.addToQuantity(i, this.form.value);
    }

    addToQuantityCardOrderParts = (i, object) => {
        this.DocService.addToQuantityCardOrderParts(i, this.form.value, object);
    }
    changeSalePercent = () => {
        this.DocService.saleWithPercentInput(this.form.value, this.mode);
    }
    changeBonusPercent(): void {
        if (!this.form.get('bonuses').value) {
            this.form.get('bonuses').setValue(0);
        }
        this.DocService.setBonuses(this.form.get('bonuses').value);
    }
    checkedCheckbox = (event, mode) => {
        if (event.target.checked === true) {
            switch (mode) {
                case 'birthday':
                    this.form.get('birthday_check').setValue(true);
                    this.form.get('sale').setValue(10);
                    break;
                case 'pickup':
                    if (this.form.value.birthday_check === true) {
                        this.form.get('pickup_check').setValue(true);
                        this.form.get('sale').setValue(10);
                    } else if (this.form.value.birthday_check === false) {
                        this.form.get('pickup_check').setValue(true);
                        this.form.get('sale').setValue(5);
                    }
                    this.pickupSwitch('disable');
                    break;
            }
            this.changeSalePercent();
        } else if (event.target.checked === false) {
            switch (mode) {
                case 'birthday':
                    if (this.form.value.pickup_check === true) {
                        this.form.get('birthday_check').setValue(false);
                        this.form.get('sale').setValue(5);
                    } else if (this.form.value.pickup_check === false) {
                        this.form.get('birthday_check').setValue(false);
                        this.form.get('sale').setValue(0);
                    }
                    break;
                case 'pickup':
                    if (this.form.value.birthday_check === true) {
                        this.form.get('pickup_check').setValue(false);
                        this.form.get('sale').setValue(10);
                    } else if (this.form.value.birthday_check === false) {
                        this.form.get('pickup_check').setValue(false);
                        this.form.get('sale').setValue(0);
                    }
                    this.pickupSwitch('enable');
                    break;
            }
        }
        this.changeSalePercent();
    }

    pickupSwitch = (mode) => {
        switch (mode) {
            case 'disable':
                this.form.get('district').setValue((this.DocService.districts.findIndex(t => t.pickup == 1) !== -1) ?
                    this.DocService.districts[this.DocService.districts.findIndex(t => t.pickup == 1)].id
                    : this.DocService.districts[0].id);
                this.form.get('street').setValue('Самовивіз');
                this.form.get('house').setValue();
                this.form.get('attic').setValue();
                this.form.get('apt').setValue();
                this.form.get('floor').setValue();
                this.form.get('house').disable();
                this.form.get('attic').disable();
                this.form.get('apt').disable();
                this.form.get('floor').disable();
                break;
            case 'enable':
                this.form.get('district').setValue(this.DocService.districts[0].id);
                this.form.get('street').setValue();
                this.form.get('house').enable();
                this.form.get('attic').enable();
                this.form.get('apt').enable();
                this.form.get('floor').enable();
                break;
        }
        this.changeSalePercent();
    }
    personnelEnable = () => {
        if (this.form.value.personnel == true) {
            this.form.get('personnel').setValue(false);
            this.form.get('sale').setValue(0);
            this.form.get('phone').setValue();
            this.pickupSwitch('enable');
        } else if (this.form.value.personnel == false) {
            this.form.get('sale').setValue(50);
            this.form.get('phone').setValue('personnel3303');
            this.pickupSwitch('disable');
            this.form.get('personnel').setValue(true);

            this.translate.get(this.form.get('phone').value).subscribe({
                next: value => {
                    this.form.get('phone').setValue(value);
                }
            });

        }
    }
    editMode = () => {
        if (this.mode === 'edit') {
            this.DocService.getAllDataAboutOrder(this.id).subscribe(val => {
                this.DocService.infoOrder = val as any;
                this.DocService.bodyOrder = val.order as any;
                this.DocService.getSum();
                this.fillingForm();
                this.DocService.getFreeProduct();
                this.getGidtsByPhone();
            });
        } else if (this.mode === 'copy') {
            this.DocService.getAllDataAboutOrder(this.id).subscribe(val => {
                this.DocService.infoOrder = val as any;
                this.DocService.bodyOrder = val.order as any;
                this.DocService.getSum();
                this.fillingForm();
                if (val.phone === 'personnel3303') {
                    this.form.get('personnel').setValue(true);
                }
                this.DocService.getFreeProduct();
                this.getGidtsByPhone();
            });
        } else if (this.mode === 'site') {
            this.DocService.getAllDataFromSite(this.id).subscribe(
                () => {
                }, (response) => {
                    console.log(response);
                },
                () => {
                    this.DocService.getSum();
                    this.fillingForm();
                    this.DocService.getFreeProduct();
                    this.fillByPhone(false);
                    if (this.DocService.infoOrder.dont_call === 1) {
                        this.modalService.open(this.DontCallNotice);
                    }
                }
            );
        } else if (this.mode === 'new') {
            this.loader = false;
            this.form.get('place').setValue(localStorage.getItem('place'));
            this.DocService.getFreeProduct();
        }
        this.unsubscribeNgOnInit();
    }

    fillingForm = () => {
        this.form.get('street').setValue(this.DocService.infoOrder.street);
        this.form.get('phone').setValue(this.DocService.infoOrder.phone ?? '');

        if (this.DocService.infoOrder.phone === 'personnel3303') {
            this.translate.get(this.form.get('phone').value).subscribe({
                next: value => {
                    this.form.get('phone').setValue(value);
                }
            });
        }

        this.form.get('attic').setValue(this.DocService.infoOrder.attic ?? '');
        this.form.get('apt').setValue(this.DocService.infoOrder.apt ?? '');
        this.form.get('bonuses').setValue(this.DocService.infoOrder.bonuses ?? 0);
        this.form.get('sale').setValue(this.DocService.infoOrder.sale ?? 0);
        this.form.get('house').setValue(this.DocService.infoOrder.house ?? '');
        this.form.get('floor').setValue(this.DocService.infoOrder.floor ?? '');
        this.form.get('ord').setValue(this.DocService.infoOrder.stick ?? 0);
        this.form.get('edu').setValue(this.DocService.infoOrder.stick_edu ?? 0);
        this.form.get('comment').setValue(this.DocService.infoOrder.comment ?? '');
        this.form.get('place').setValue(this.DocService.infoOrder.place ?? localStorage.getItem('place'));
        this.form.get('district').setValue(this.DocService.infoOrder.district_id ?? this.DocService.districts[0].id);
        this.form.get('zone').setValue(this.DocService.infoOrder.zone_id ?? this.DocService.zones[0].id);
        switch (this.DocService.infoOrder.terminalcheck == 1 && this.DocService.infoOrder.payment_type) {
            case 'online':
                this.form.get('terminal').setValue(this.DocService.terminals.find(item => item.paid_status === 1).id);
                break;
            case 'card':
                this.form.get('terminal').setValue(this.DocService.terminals.find(item => item.paid_status === 0).id);
                break;
            default:
                this.form.get('terminal').setValue(this.DocService.terminals.find(
                    item => item?.id === this.DocService.infoOrder.terminal)?.id);
                break;
        }
        if (this.DocService.infoOrder.terminalcheck == 1) {
            this.form.get('terminal_check').setValue(true);
            this.form.get('terminal').enable();
        } else {
            this.form.get('terminal_check').setValue(false);
        }
        if (this.DocService.infoOrder.birthday && this.DocService.infoOrder.birthday == 1) {
            this.form.get('birthday_check').setValue(true);
            if (this.mode === 'site') {
                this.form.get('sale').setValue(10);
                this.DocService.saleWithPercentInput(this.form.value);
            }
        } else {
            this.form.get('birthday_check').setValue(false);
        }
        if (this.DocService.infoOrder.futurecheck && this.DocService.infoOrder.futurecheck == 1) {
            this.form.get('pre_order_check').setValue(true);
            this.form.get('datetime').setValue(`${this.DocService.infoOrder.futuredate}T${this.DocService.infoOrder.futuretime}`);
        } else {
            this.form.get('pre_order_check').setValue(false);
        }
        if (this.DocService.infoOrder.future_datetime) {
            this.form.get('pre_order_check').setValue(true);
            this.form.get('datetime').setValue(this.DocService.infoOrder.future_datetime);
        }
        this.preOrder();
        if (this.DocService.infoOrder.pickupcheck && this.DocService.infoOrder.pickupcheck == 1) {
            this.form.get('pickup_check').setValue(true);
            this.form.get('street').setValue('Самовивіз');
            this.form.get('house').setValue();
            this.form.get('attic').setValue();
            this.form.get('apt').setValue();
            this.form.get('floor').setValue();
            this.form.get('house').disable();
            this.form.get('attic').disable();
            this.form.get('apt').disable();
            this.form.get('floor').disable();
            if (this.mode === 'site') {
                if (this.DocService.infoOrder.pickupcheck == 1 && this.DocService.infoOrder.birthday == 1){
                    this.form.get('sale').setValue(10);
                    this.DocService.saleWithPercentInput(this.form.value);
                }else {
                    this.form.get('sale').setValue(5);
                    this.DocService.saleWithPercentInput(this.form.value);
                }
            }
        } else {
            this.form.get('pickup_check').setValue(false);
        }
        this.DocService.setBonuses(this.form.get('bonuses').value);
        this.checkOnEdit();
        this.loader = false;
    }
    switchTerminal = () => {
        if (this.form.value.terminal_check === true) {
            this.form.get('terminal').enable();
        } else if (this.form.value.terminal_check === false) {
            this.form.get('terminal').disable();
        }
    }
    preOrder = () => {
        if (this.form.value.pre_order_check === true) {
            this.form.get('pre_order_check').setValue(true);
            this.form.get('datetime').enable();
        } else if (this.form.value.pre_order_check === false) {
            this.form.get('pre_order_check').setValue(false);
            this.form.get('datetime').disable();
        }
    }
    unsubscribeNgOnInit = () => {
        this.DocService.getAllCat().subscribe().unsubscribe();
        this.DocService.getAllProduct().subscribe().unsubscribe();
        this.DocService.getAllZones().subscribe().unsubscribe();
        this.DocService.getAllDistricts().subscribe().unsubscribe();
        this.DocService.getAllPlaces().subscribe().unsubscribe();
        this.DocService.getAllTerminals().subscribe().unsubscribe();
    }
    getGidtsByPhone(): void{
        this.DocService.getGiftByPhone(this.form.value.phone)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => this.giftCount = value.length,
            });
    }
    fillByPhone = (overwrite?: boolean) => {

        this.DocService.lastAddress = '';

        this.DocService.getGiftByPhone(this.form.value.phone)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => this.giftCount = value.length,
            });
        this.DocService.fillByPhone(this.form.value.phone).subscribe({
            next: val => {
                if (val) {
                    if (overwrite){
                        this.form.get('street').setValue(val.street ?? '');
                        this.form.get('house').setValue(val.house ?? '');
                        this.form.get('attic').setValue(val.attic ?? '');
                        this.form.get('apt').setValue(val.apt ?? '');
                        this.form.get('floor').setValue(val.floor ?? '');
                        this.form.get('place').setValue(val.place ?? localStorage.getItem('place'));
                        this.form.get('district').setValue(val.district_id ?? this.DocService.districts[0].id);
                    }else{
                        this.DocService.lastAddress = val;
                    }
                }
            },
        });
        this.DocService.lastBirthday(this.form.value.phone)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => this.lastBirthday = value
            });
        this.DocService.getBlackListByPhone(this.form.value.phone)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                next: value => this.blackList = value
            });
    }
    autosubstitution(): void{
        const lastAddress = this.DocService.lastAddress;
        this.form.get('street').setValue(lastAddress.street);
        this.form.get('house').setValue(lastAddress.house);
        this.form.get('attic').setValue(lastAddress.attic);
        this.form.get('apt').setValue(lastAddress.apt);
        this.form.get('floor').setValue(lastAddress.floor);
        this.form.get('district').setValue(lastAddress.district_id ?? this.DocService.districts[0].id);
    }
    SortBy = () => {
        this.DocService.bodyOrder.sort((item1, item2) =>
            (parseFloat(String(item1.position_in_check)) > parseFloat(String(item2.position_in_check))) ? 1 : -1);
    }
    checkOnEdit = () => {
        this.formCheckOnEdit.formBefore = JSON.parse(JSON.stringify(this.form.value));
        this.form.valueChanges.subscribe({
            next: value => {
                this.formCheckOnEdit.formAfter = JSON.parse(JSON.stringify(value));
            }
        });
    }
    deepEqual = () => {
        const o1 = this.formCheckOnEdit.formBefore;
        const o2 = this.formCheckOnEdit.formAfter;
        const different = [];
        Object.keys(o2).reduce((diff, key) => {
            if (o1[key] === o2[key]) {
                return diff;
            }
            return different.push(key);
        }, {});
        return different;
    }
    saveEditableColumn = () => {
        const o1 = this.formCheckOnEdit.formBefore;
        const o2 = this.formCheckOnEdit.formAfter;
        const different = this.deepEqual;
        const body = [];
        different().forEach(value => {
            switch (value) {
                case 'datetime':
                    body.push({
                        column: 'futuredate',
                        user_id: localStorage.getItem('id'),
                        old_value: (o1[value]) ? o1[value].slice(0, 10) : o1[value],
                        new_value: (o2[value]) ? o2[value].slice(0, 10) : o2[value],
                        order_id: this.id
                    });
                    body.push({
                        column: 'futuretime',
                        user_id: localStorage.getItem('id'),
                        old_value: (o1[value]) ? o1[value].slice(11, 16) : o1[value],
                        new_value: o2[value].slice(11, 16),
                        order_id: this.id
                    });
                    break;
                default:
                    body.push({
                        column: value,
                        user_id: localStorage.getItem('id'),
                        old_value: o1[value],
                        new_value: o2[value],
                        order_id: this.id
                    });
                    break;
            }
        });
        return body;
    }

    hasUnconfirmedItems(): boolean {
        return this.DocService.bodyOrder.some(item => !item.confirmed);
    }

    sort(): void{
        this.DocService.bodyOrder.sort((a, b) => this.getCatIdOrder(a.cat_id) - this.getCatIdOrder(b.cat_id));
    }

    getCatIdOrder(catId: number): number {
        return catId === 15 ? 1   // Сеты
            : [3].includes(catId) ? 2   // Роллы
                : catId === 22 ? 3   // Філадельфія
                    : catId === 10 ? 4   // Роллы от шефа
                        : catId === 17 ? 5   // Закуски
                            : catId === 12 ? 6   // Суши
                                : catId === 5  ? 7   // Лапша
                                    : catId === 6  ? 8   // Рис
                                        : [7, 21].includes(catId) ? 9   // Напитки
                                            : catId === 16 ? 10  // Додатково
                                                : 2; // По умолчанию
    }

    activateWinnerList = ( obj, id: number) => {
        const activateId = {
            activated_by_id: localStorage.getItem('id')
        };
        this.winnerListService.update(id, activateId).subscribe({
            complete: () => {
                obj.confirmed = true;
                this.fillByPhone();
            }
        });
    }

    ngOnDestroy(): void {
        this.form.valueChanges.unsubscribe();
        this.DocService.clearAndRefreshArrays();
    }


}
