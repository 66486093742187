    <form class="form-inline ml-3 mt-3">
        <div class="form-group hidden">
            <div class="input-group">
                <input name="datepicker"
                       class="form-control"
                       ngbDatepicker
                       #datepicker="ngbDatepicker"
                       [autoClose]="'outside'"
                       (dateSelect)="onDateSelection($event)"
                       [displayMonths]="2"
                       [dayTemplate]="t"
                       type="hidden"
                       [startDate]="fromDate!"
                       tabindex="-1">
                <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
                </ng-template>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <input #dpFromDate
                       class="form-control" placeholder="yyyy-mm-dd"
                       name="dpFromDate"
                       [value]="formatter.format(fromDate)"
                       (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                <div class="input-group-append">
                    <button class="btn btn-outline-success calendar" (click)="datepicker.toggle()"
                            type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-calendar2-date" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                            <path
                                    d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group ml-2">
            <div class="input-group">
                <input #dpToDate
                       class="form-control" placeholder="yyyy-mm-dd"
                       name="dpToDate"
                       [value]="formatter.format(toDate)"
                       (input)="toDate = validateInput(toDate, dpToDate.value)">
                <div class="input-group-append">

                    <button class="btn btn-outline-success calendar" (click)="datepicker.toggle()"
                            type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-calendar2-date" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                            <path
                                    d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
<!--        [disabled]="callBack.length === 0"-->
        <button class="btn btn-success ml-2"  (click)="openCallBack(callback, 'xl')">
            {{'ORDER_HISTORY.VIEW_CALLS_FROM_THE_SITE' | translate}}</button>
    </form>
    <div class="mt-2 ml-3 w-25">
        <app-search-input (searchChange)="searchInput = $event"> </app-search-input>
    </div>
    <table class="mt-3 w-100 table">
    <thead>
    <tr>
        <th>ID</th>
        <th>{{'ORDER_HISTORY.PHONE' | translate}}</th>
        <th>{{'ORDER_HISTORY.DELIVERY_TYPE' | translate}}</th>
        <th>{{'ORDER_HISTORY.ADDRESS' | translate}}</th>
        <th>{{'ORDER_HISTORY.COMMENT' | translate}}</th>
        <th>{{'ORDER_HISTORY.PAYMENT_TYPE' | translate}}</th>
        <th>{{'ORDER_HISTORY.PIZZA_SUSHI' | translate}}</th>
        <th>{{'ORDER_HISTORY.DELIVERY_TIME' | translate}}</th>
        <th>{{'ORDER_HISTORY.PLACE' | translate}}</th>
        <th>{{'ORDER_HISTORY.SOURCE' | translate}}</th>
        <th>{{'ORDER_HISTORY.ACTION' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let order of orders$ | async | filterAll: searchInput">
        <tr [ngClass]="{'table-success': order.state == 'done', 'table-danger': order.state == 'canceled'}" *ngIf="order.state != 'cart'">
        <td>{{order.id}}</td>
        <td>{{order.phone}}</td>
        <td *ngIf="order.delivery_type == 'pickup'">{{'ORDER_HISTORY.PICKUP' | translate}}</td>
        <td *ngIf="order.delivery_type == 'delivery'">{{'ORDER_HISTORY.DELIVERY' | translate}}</td>
        <td>
            {{order.address}}
            <span *ngIf="order.house != null">{{order.house}}</span>
            <span *ngIf="order.apt != null">, {{'QUICK-SALE.APARTMENT' | translate}} {{order.apt}}</span>
        </td>
        <td>{{order.comment}}</td>
        <td *ngIf="order.payment_type == 'cash'">{{'ORDER_HISTORY.CASH' | translate}}</td>
        <td *ngIf="order.payment_type == 'card'">{{'ORDER_HISTORY.NOT_CASH' | translate}}</td>
        <td *ngIf="order.payment_type == 'online'">{{'ORDER_HISTORY.LIQPAY' | translate}}</td>
        <td *ngIf="order.source == 'pizza'">{{'ORDER_HISTORY.PIZZA' | translate}}</td>
        <td *ngIf="order.source == 'sushi'">{{'ORDER_HISTORY.SUSHI' | translate}}</td>
        <td>{{order.updated_at | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
        <td>{{order.place_name}}</td>
        <td *ngIf="order.app == 'site'">{{'ORDER_HISTORY.SITE' | translate}}</td>
        <td *ngIf="order.app == 'ios'">{{'ORDER_HISTORY.APP_IOS' | translate}}</td>
        <td *ngIf="order.app == 'android'">{{'ORDER_HISTORY.APP_ANDROID' | translate}}</td>
        <td class="text-center flex flex-column">
            <button class="btn btn-success btn-sm" (click)="open(result, 'lg' , order.id)">{{'ORDER_HISTORY.REVIEW' | translate}}</button>
            <button *ngIf="order.state == 'canceled'" (click)="confirmFromSiteOrders(order.id, order.city_id, order.place_id)" class="btn btn-primary btn-sm mt-2">{{'ORDER_HISTORY.RETURN' | translate}}</button>
        </td>
    </tr>
    </ng-container>
    </tbody>
</table>

<ng-template #result>
    <div class="modal-header">
        <h2>{{'ORDER_HISTORY.ORDER_INFO' | translate}}</h2>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
        <span class="font-weight-bold">{{'ORDER_HISTORY.PHONE' | translate}}: </span>
        <span>{{orderInfo$.value.phone}}</span>
        </div>
        <div *ngIf="orderInfo$.value.street != null" class="mt-1">
        <span class="font-weight-bold">{{'ORDER_HISTORY.ADDRESS' | translate}}: </span>
        <span>{{orderInfo$.value.street}}</span>
        </div>
        <div *ngIf="orderInfo$.value.comment != null" class="mt-1">
        <span class="font-weight-bold">{{'ORDER_HISTORY.COMMENT' | translate}}: </span>
        <span>{{orderInfo$.value.comment}}</span>
        </div>
        <table *ngIf="orderInfo$" class="table mt-3">
            <thead>
            <tr>
                <th>{{'ORDER_HISTORY.PRODUCT_NAME' | translate}}</th>
                <th>{{'ORDER_HISTORY.QUANTITY' | translate}}</th>
                <th>{{'ORDER_HISTORY.PRICE' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of orderInfo$.value.order">
                <td>{{object.product_name}}</td>
                <td>{{object.quantity}} шт.</td>
                <td>{{object.quantity * object.price}} грн</td>
            </tr>
            <tr>
                <td></td>
                <td class="font-weight-bold">{{'ORDER_HISTORY.TOTAL_SUM' | translate}}:</td>
                <td>
                    <button class="btn btn-outline-primary" disabled>{{allSum}} грн</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer justify-content-center">
        <button class="btn btn-success" (click)="close()">{{'ORDER_HISTORY.OK' | translate}}</button>
    </div>
</ng-template>

    <ng-template #callback>
        <div class="modal-header">
            <h2>{{'ORDER_HISTORY.REQUEST_FROM_THE_SITE_TO_CALL_ME_BACK' | translate}}</h2>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row row-cols-3">
                <ng-container *ngFor="let object of callBack; index as i">
                    <div class="col mb-3">
                        <div class="card">
                            <div class="card-header m-1 d-flex align-items-center justify-content-center" [ngClass]="{'done': object.status == 'new', 'pending': object.status == 'pending', 'canceled': object.status == 'cancelled'}">
                                <p class="m-0 mr-2">{{object.phone}}</p>
                                <a href="tel:{{object.phone}}" class="btn btn-sm btn-primary mr-2" (click)="putCallBackRequest(orderStatusEnum.Pending, object.id)">☏</a>
                                <a *ngIf="object.status == 'pending'" class="btn btn-sm btn-success mr-2" (click)="putCallBackRequest(orderStatusEnum.Done, object.id)">✔</a>
                                <a *ngIf="object.status == 'pending'" class="btn btn-sm btn-danger" (click)="putCallBackRequest(orderStatusEnum.Canceled, object.id)">✖</a>
                            </div>
                            <div class="text-center">
                                <hr class="m-0">
                                <span>{{object.created_at | date:'dd.MM.yyyy HH:mm'}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-success" (click)="close()">{{'ORDER_HISTORY.OK' | translate}}</button>
        </div>
    </ng-template>
